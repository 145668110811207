

html, body {
  height: 100%;
  font-family: 'Calibri';
  background-color: #fff;
  overflow-y: auto;   
  scrollbar-width: thin;
}

.full-height {
  height: 100%;    
}

.app{
  height: 100%;
  max-width: 960px;
  margin: 0 auto ;
  background-color: #fff;
  color: white;
  
 
}

.main-view{
  height: 100%;  
  margin-top: 60px;
}

.main-content{
  position:relative;  
  height: 85%;  
  
  margin-bottom: 100px;
}

.content{
    position: relative;
    top: 50px;
   padding-bottom: 5px;
    
}
.topnav{
    z-index:10;	 	
    position: relative;
    width: 100%;
    top: 50px;
    	
}




.navigation{
  background-color: black;
}
.buttonnav{
  
}
.map-view{
 position: relative;
  height:100vh;
}

.map-view-content{

  height: 90vh;
  
  
 }


.buttonmap{
  position: relative;
  width: 45px;
  
  background-color: transparent;
  margin: 10px 10px;
  padding: 3px 0px;
  /* box-shadow: 0px 6px 10px 3px #ccc; */

}

.img-shadow{
  
  filter: drop-shadow(0px 6px 10px 3px #ccc);
}
.startside{
  background-color: black;
}
.alarm{
  background-color: #252525;
}
.allgemein{
  background-color: #252525;
}
.buttontext{
    color: white;
    font-size: 14px;
}


.ol-popup {
    
    position: absolute;    
    width: 200px;    
    padding:  0px;
    border-radius: 0px;    
    
    z-index: 20;
  }
  .side-button-left{
    
      border-right: 1px solid #888;
      border-top: 1px solid #888;
      border-bottom: 1px solid #888;
      border-bottom-right-radius:0px;
      border-top-right-radius:0px;
      min-width:  100px; 
      height: 40px;
      background-color: rgba(255,255,255,0.8);
      display: flex;
      justify-content: flex-end;
      align-items: center;



  }
  .side-button-right{
    
    border-left: 1px solid #888;
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
    border-bottom-left-radius:0px;
    border-top-left-radius:0px;
    min-width:  100px; 
    height: 40px;
    background-color: rgba(255,255,255,0.8);
    
    display: flex;
    justify-content: flex-start;
    align-items: center;



}

  .side-image{
   
    
      width:  50px; 
      height: 40px;
    

  }
  
  
  .ctrl-left{
    position: fixed;
    left: 0px;
    height: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1px;
  }

  .ctrl-right{
    position: fixed;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1px;
  }
  
 .map-footer{
   
   position: fixed;
   left:0px;
   bottom: 5px;
   width: 100%;

   display: flex;
   justify-content: center;
   
   
   
 }

 
.aicon{
  width: 20px;
  height: 20px;
}

.btn-border{
  border: 1px solid #888;
  border-radius: 5px;
}

.shadow-box{
  box-shadow: 0px 6px 10px 3px #ccc;
  color: #393E41;
  padding: 20px 20px;
  margin: 20px 20px;
}

.pulse {
  display: inline-block;
  
  text-align: center;
  height: 50px;
  width: 300px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  
}
.pulse:after {
  content: '';
 
  display: block; 
  background: url(./images/logo_clean.png) 0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 3s pulse linear infinite;
  -moz-animation: 3s pulse linear infinite;
  -o-animation: 3s pulse linear infinite;
  animation: 3s pulse linear infinite;
  clip: rect(0, 0px , 100px, 0);
}
.pulse:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 3px;

}



@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
    opacity: 0.4;
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
    opacity: 0.6;
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
    opacity: 0.8;
  }
  50% {
    clip: rect(0, 500px, 100px, 0);
     opacity: 1;
  }

  80% {
    clip: rect(0, 500px, 100px, 0);
     opacity:0;
  }

  90% {
    clip: rect(0, 400px, 100px, 0);
    opacity: 0;
  }

  100% {
    clip: rect(0, 400px, 100px, 0);
    opacity:0;
 }
}


@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
  }
  50% {
    clip: rect(0, 500px, 100px, 0);
     opacity:1;
  }

  80% {
    clip: rect(0, 500px, 100px, 0);
     opacity:0;
  }

  90% {
    opacity: 0;
  }

  100% {
    clip: rect(0, 500px, 100px, 0);
    opacity:0;
 }
  
}

.img-impulse{
  
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: auto;
  clip: rect(0, 20px , 100px, 0);
}

.img-impulse:after {
  width: 100%;
  height: 100%;
  animation: 3s pulse linear infinite;
  clip: rect(0, 20px , 50px, 0);
}

.intro-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info-point{
 position: absolute;
 background-color: red;
 border-radius: 5px;
 top:-5px;
 right: -5px;
 width: 10px;
 height: 10px;
 
}

.overview{
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;   
 } 

.as-info-view{
 
}

.overview-btn{
  
  font-size: 18px;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 5px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menue-container{
  display: flex;
  justify-content:  space-between;
  padding: 0 10px;
}

.menue-btn{
  cursor: pointer;
  color: rgb(57, 62, 65);
  margin: 5px 20px;
  padding: 5px 10px;
  border-bottom:1px solid #393e41;
}

.menue-btn:hover{
  
  font-weight: bold;
}

.header-nonflex{
  width:100%;
  z-index: 1000;
}

.header-container{
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  background-color:#fff;
  border-bottom: 1px solid #393E41;
  color: #cdcdcd;
  max-width: 960px;
  width: 100%;
  z-index: 1000;
  
}

.logo-img{
  display: block; 
  background: url(./images/logo_.svg) 0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(./images/logo.svg)
}

.station-short-info{
  padding: 20px 20px;
  margin-bottom: 20px;   
	background-color:#fff;
  cursor: pointer;
  box-shadow: 0px 6px 10px 3px #ccc;
}

.header-text{
  color: #393E41;
  font-weight: bold;
}

.search-content{
  
}

.modal{
}